<template>
  <div class="websocket-container">
    <h1>WebSocket 测试页面</h1>
<!--    <input style="width: 600px;height: 30px;" type="text" v-model="socketUrl" placeholder="输入消息ws地址"/>-->
    <div style="display: flex;flex-direction: row;align-items: center;">
      账号：<input :disabled="isLogin" style="width: 300px;height: 20px;margin-right: 10px;" type="text" v-model="username" placeholder="账号"/>
      密码：<input :disabled="isLogin" style="width: 300px;height: 20px;" type="text" v-model="password" placeholder="密码"/>
      <button @click="login" style="margin-left: 10px;">{{loginText}}</button>
      <button @click="loginOut" style="margin-left: 10px;">退出登录</button>
    </div>

    <textarea id="console" ref="console"></textarea>
    <input style="width: 300px;height: 50px;" type="text" v-model="message" placeholder="输入消息..."/>
    <button @click="startConnection">立即连接</button>
    <button @click="sendMessage">发送消息</button>
    <button @click="clearMessage">清空消息</button>
    <button @click="closeConnection">关闭连接</button>
    <div style="margin-bottom: 3px;">操作码</div>
    <div style="margin-bottom: 3px;">群历史消息：00620{"chatlinkid":"-315","startmid":""}</div>
    <textarea id="wsOperCode" disabled>/**
     * 心跳请求
     */
    HeartbeatReq: 1,

    /**
     * 握手请求
     */
    HandshakeReq: 2,
    /**
     * 握手响应
     */
    HandshakeResp: 3,

    /**
     * 进入群组请求
     */
    JoinGroupReq: 4,
    /**
     * 进入群组响应
     */
    JoinGroupResp: 5,

    /**
     * 进入群组通知
     */
    JoinGroupNtf: 6,

    /**
     * 离开群组通知
     */
    LeaveGroupNtf: 7,

    /**
     * 点对点聊天（私聊）请求
     */
    P2pChatReq: 8,

    /**
     * 点对点聊天（私聊）通知
     */
    P2pChatNtf: 9,

    /**
     * 群聊请求
     */
    GroupChatReq: 10,

    /**
     * 群聊通知
     */
    GroupChatNtf: 11,

    /**
     * 获取p2p聊天记录数据-请求
     */
    P2pQueryChatRecordReq: 12,

    /**
     * 运行js脚本
     */
    RunJsNtf: 14,

    /**
     * 让客户端关闭当前页面（只作用于WEB端）
     */
    ClosePage: 15,

    /**
     * 消息提示
     */
    MsgTip: 16,


    /**
     * 分页获取在线观众请求
     */
    PageOnlineReq: 18,

    /**
     * 分页获取在线观众响应
     */
    PageOnlineResp: 19,

    /**
     * 更新token
     */
    UpdateTokenReq: 20,

    /**
     * 更新token响应
     */
    UpdateTokenResp: 21,

    /**
     * 撤回消息
     */
    UnsendMsgReq: 22,

    /**
     * 撤回消息通知
     */
    UnsendMsgNtf: 23,
    /**
     * 用户动作日志
     */
    UserActionLogReq: 24,
    /**
     * 我告诉服务器，张三发给我的私聊消息已读
     */
    P2pAlreadyReadReq: 25,
    /**
     * 服务器告诉张三，张三发给李四的私聊，李四已经阅读
     */
    P2pAlreadyReadNtf: 26,
    /**
     *  查询未读私聊消息数请求
     */
    P2pRecentChatListReq: 27,
    /**
     *  查询未读私聊消息数响应
     */
    P2pRecentChatListResp: 28,


    //	 ----------- 下面是微信的命令码 --------------------------------------------------------------------------------

    /**
     * Wx握手请求
     */
    WxHandshakeReq: 599,
    /**
     * Wx握手响应
     */
    WxHandshakeResp: 600,

    /**
     *  服务器通知用户"有人请求加你为好友啦"-- Server-->Client
     */
    WxApplyFriendNtf: 601,

    /**
     *  朋友间的聊天请求-- Client-->Server
     */
    WxFriendChatReq: 602,

    /**
     *  朋友间的聊天通知-- Server-->Client
     */
    WxFriendChatNtf: 603,

    /**
     * 获取两好友间聊天记录--请求-- Client-->Server
     */
    WxFriendMsgReq: 604,

    /**
     * 获取两好友间聊天记录--响应-- Server-->Client
     */
    WxFriendMsgResp: 605,

    /**
     * 群聊请求-- Client-->Server
     */
    WxGroupChatReq: 606,
    /**
     * 群聊通知-- Server-->Client
     */
    WxGroupChatNtf: 607,

    /**
     * 已读请求： 告诉服务器，和某人的私聊信息已经阅读了
     */
    WxFriendAlreadyReadReq: 608,
    /**
     * 已读通知： 服务器转告张三，张三发给李四的私聊，李四已经阅读
     */
    WxFriendAlreadyReadNtf: 609,

    /**
     * 已读请求： 告诉服务器，某群的信息已经阅读了
     */
    WxGroupAlreadyReadReq: 610,
    /**
     * 已读通知： 服务器转告群员，张三已经阅读过群消息（暂不实现）
     */
    // WX_GROUP_ALREADY_READ_NTF: 611,

    /**
     * 撤回消息请求
     * 规则：
     * 1、自己只能撤回两分钟以内的消息
     2、超级管理员可以不受限制地随时随地撤回任何人的消息（前端用isSuper标识的，后端会二次检查）
     */
    WxWithdrawMsgReq: 612,
    /**
     * 撤回消息通知
     */
    WxWithdrawMsgNtf: 613,

    /**
     * 离群通知。当某用户被T出群，或群被删除时，用户会收到这个通知
     * 消息体中有个type字段，用以标示离群原因：1：主动退群；2：被T出群；3：群被删除
     */
    WxLeaveGroupNtf: 614,

    /**
     * 你们不是好友
     * 你发消息给对方时，你并不是对方的好友，这时候前端提示当前用户发送申请好友请求
     */
    WxNotFriendNtf: 615,

    /* 群聊消息请求 */
    WxGroupMsgReq: 620,
    /* 群聊消息响应 */
    WxGroupMsgResp: 621,

    /* 操作通知 */
    WxUserOperNtf: 700,

    /* 异常通知 */
    WxFriendErrorNtf: 701,

    /* 会话详情信息请求 */
    WxChatItemInfoReq: 708,

    /* 会话详情信息响应 */
    WxChatItemInfoResp: 709,

    /* 进入会话-离开会话 */
    WxSessionOperReq: 710,

    /*用户系统通知*/
    WxUserSysNtf: 738,

    /* 群操作通知(TCP) */
    WxGroupOperNtf: 750,

    /* 焦点状态机请求(TCP) */
    WxFocusReq: 776,

    /* 焦点状态机通知(TCP) */
    WxFocusNtf: 777,

    /* ------ webrtc start ----------- */
    /**
     * a --> s   a向b发起通话请求
     */
    WxCall01Req: 800,
    /**
     * s --> b   s通知b，此时a和b要处于“占线”状态，后续呼入要直接拒绝
     */
    WxCall02Ntf: 801,
    /**
     * b --> s   b回复s：同意通话，或拒绝通话（拒绝原因：1、对方拒接，2、对方不在线， 3、对方占线，99、其它原因）
     */
    WxCall03ReplyReq: 802,
    /**
     * s --> a   s转告a
     */
    WxCall04ReplyNtf: 803,
    /**
     * a --> s   a向b提供offer，需要提供 sdp
     */
    WxCall05OfferSdpReq: 804,
    /**
     * s --> b   s转发给b
     */
    WxCall06OfferSdpNtf: 805,
    /**
     * b --> s   b向a回复Answer，需要提供 sdp
     */
    WxCall07AnswerSdpReq: 806,
    /**
     * s --> a   s转发给a
     */
    WxCall08AnswerSdpNtf: 807,
    /**
     * a --> s   a向b提供offer，需要提供 e.candidate
     */
    WxCall09OfferIceReq: 808,
    /**
     * s --> b   s转发给b
     */
    WxCall10OfferIceNtf: 809,
    /**
     * b --> s   b向a回复Answer，需要提供 e.candidate
     */
    WxCall11AnswerIceReq: 810,
    /**
     * s --> a   s转发给a
     */
    WxCall12AnswerIceNtf: 811,
    /**
     * a或b --> s   发起结束通话请求
     */
    WxCall13EndReq: 812,
    /**
     * s    --> a和b   通知结束通话，通话原因：1、对方主动挂电话；2、网络不好
     */
    WxCall14EndNtf: 813,
    /**
     * a    --> s   取消通话请求（a发起通话后，在对方响应前进行了取消操作）
     */
    WxCall02_1CancelReq: 814,
    /**
     * s    --> a&b   取消通话通知（a发起通话后，在对方响应前进行了取消操作）
     */
    WxCall02_2CancelNtf: 815,
    /* ------ webrtc end ----------- */
    WxCallRespNtf: 888,

    /**
     * 好友在线状态通知
     */
    WxFriendOnlineRespNtf: 10006,</textarea>
  </div>
</template>

<script>
import {wscommand_len, commandReverse} from '@/assets/js/ws/command.js';
import pako from 'pako';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      loginText:'立即登录',
      isLogin:false,
      username:'',
      password:'',
      password1:'',
      socketUrl:'',
      message: '00620{"chatlinkid":"-315","startmid":""}',
      messages: [],
      socket: null,
    };
  },
  mounted() {
    let username = Cookies.get('username');
    let password = Cookies.get('password');
    if (username!==''&&password!==''){
      this.username = username
      this.password1 = password
      this.login()
    }
  },
  beforeUnmount() {
    this.closeConnection();
  },
  methods: {
    async login() {
      if (this.password1===''){
        if (this.username===''||this.password===''){
          alert('请输入账密')
          return;
        }
      }
      let param ={
        loginname:this.username,
        pd5:this.getPlainPwd(this.password===''?this.password1:this.password),
        authcode:''
      };
      axios.post('/mytio/login.tio_x', param,{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
          .then(response => {
            if(response.data.ok){
             //登录成功
              Cookies.set('username',this.username)
              Cookies.set('password',this.password===''?this.password1:this.password)
              this.getUserInfo()
            }
          })
          .catch(error => {
            console.log(error.message || '发生错误');
          })
          .finally(() => {
          });
    },
    loginOut(){
      Cookies.remove('tio_session'); // 删除Cookie
      Cookies.remove('username');
      Cookies.remove('password');
      location.reload();
    },
    getPlainPwd(password) {
      const key1 = "$";
      const key2 = "{";
      const key3 = "9DA015C1866616ABBE371EB25DED67E8";
      const key4 = "}";
      let palinStr = `${key1}${key2}${key3}${key4}${password}`;
      return CryptoJS.MD5(CryptoJS.enc.Latin1.parse(palinStr)).toString();
    },
    getUserInfo(){
      axios.get('/mytio/user/curr.tio_x',{
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
          .then(response => {
            console.log(response.data);
            if (response.data.ok){
              let data = response.data.data;
              this.isLogin = true
              this.username = data.loginname
              this.password = ' '
              this.loginText = '已登录'
              this.getImSocket()
            }
          })
          .catch(error => {
            console.log(error.message || '发生错误');
          })
          .finally(() => {
          });
    },
    getImSocket(){
      axios.get('/mytio/im/imserver.tio_x',{
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
          .then(response => {
            console.log(response.data);
            if (response.data.ok){
              let data = response.data.data;
              let type = 'ws';
              if (data.ssl==1){
                type = 'wss';
              }
              this.socketUrl = type+'://'+data.ip+':'+data.port+'/?wx=1&tio_session='+Cookies.get('tio_session')
              this.initSocket()
            }
          })
          .catch(error => {
            console.log(error.message || '发生错误');
          })
          .finally(() => {
          });
    },
    initSocket() {
      console.log(this.socketUrl)
      this.socket = new WebSocket(this.socketUrl);

      // 设置 binaryType 为 arraybuffer
      this.socket.binaryType = 'arraybuffer';

      this.socket.onopen = () => {
        this.appendMessage('WebSocket 连接已建立\n\n');
      };

      this.socket.onmessage = (event) => {

        //解码数据
        var arrayBuffer = event.data;
        // console.log("ws数据22：",arrayBuffer);
        // console.log('receive data: ', arrayBuffer)
        var uint8array = null;
        var firstbyte = new Uint8Array(arrayBuffer, 0, 2);
        var firstchar = new TextDecoder('utf-8').decode(firstbyte);
        // var isZipped = false;
        var isZippedStr = '';
        if (firstchar.indexOf('x') != -1) {
          // 压缩过的
          isZippedStr = '(zipped)';
          var zipedUint8array = new Uint8Array(arrayBuffer, 2);
          uint8array = pako.ungzip(zipedUint8array);
        } else {
          uint8array = new Uint8Array(arrayBuffer);
        }
        var data = new TextDecoder('utf-8').decode(uint8array);
        // console.log('receive data' + isZippedStr + ': ' + data)
        if (!data || data.length < wscommand_len) {
          console.error('data wrong' + isZippedStr + ', the data length must be >= ' + wscommand_len, data);
          return;
        }
        var commandstr = data.substr(0, wscommand_len);
        var commandName = commandReverse[commandstr];
        console.log('CommandCode=' + commandstr);
        console.log('commandName=' + commandName);
        if (!commandName) {
          console.info('commandstr is ' + commandstr + isZippedStr + ', but con not find commandName');
          return;
        }

        var bodyStr = null;
        var bodyObj = null;
        if (data.length > wscommand_len) {
          bodyStr = data.substr(wscommand_len);
          // console.info('received:' + commandName + isZippedStr + '\r\n, body string is :' + bodyStr);
          try {
            bodyObj = JSON.parse(bodyStr);
          } catch (error) {
            console.error(
                'can not parse to object, commandName is ' +
                commandName +
                isZippedStr +
                ', body string is ' +
                bodyStr
            );
            return;
          }
        }
        // 处理解码后的字符串
        console.log('二进制数据 bodyObj:', bodyObj);

        this.appendMessage(`服务器返回: 操作码类型：commandName=${commandName}, 消息体： ${JSON.stringify(bodyObj)}\n\n`);

      };

      this.socket.onclose = () => {
        this.appendMessage('WebSocket 连接已关闭\n\n');
      };

      this.socket.onerror = (error) => {
        this.appendMessage(`WebSocket 错误: ${error.message}\n\n`);
      };
    },
    startConnection(){
      this.initSocket();
    },
    sendMessage() {
      if (this.message.trim() !== '') {
        this.socket.send(this.message);
        this.appendMessage(`客户端请求: ${this.message}\n\n`);
      }
    },
    clearMessage() {
      this.$refs.console.innerHTML = '';
    },
    closeConnection() {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.close();
      }
    },
    appendMessage(message) {
      this.messages.push(message);
      // this.$refs.console.innerHTML += `<p>${message}</p>`;
      this.$refs.console.innerHTML += `${message}`;
      this.$refs.console.scrollTop = this.$refs.console.scrollHeight;
    },
  },
};
</script>

<style scoped>
.websocket-container {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

#console {
  font-size: 14px;
  width: 100%;
  height: 300px;
  border: 1px solid black;
  overflow-y: scroll;
  margin-bottom: 10px;
}

#wsOperCode {
  width: 100%;
  height: 300px;
  border: 1px solid black;
  overflow-y: scroll;
  margin-bottom: 10px;
}

input, button {
  display: block;
  margin: 5px 0;
}
</style>
